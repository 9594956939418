// Generated by Framer (c747e62)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, Image, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle1 from "../css/d0WA5d5b_";
import * as sharedStyle from "../css/tittoIAe8";

const cycleOrder = ["SsKPhxJje"];

const serializationHash = "framer-XZqg2"

const variantClassNames = {SsKPhxJje: "framer-v-1l27sjz"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({amenityDesc, amenityIcon, amenityTitle, height, iconVisible, id, width, ...props}) => { return {...props, gwoKPoO7e: amenityIcon ?? props.gwoKPoO7e, TAyogJfoL: amenityTitle ?? props.TAyogJfoL ?? "Amenity Title", W71hU8lit: amenityDesc ?? props.W71hU8lit ?? "Amenity Desc", xFjzm0Bu1: iconVisible ?? props.xFjzm0Bu1 ?? true} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;amenityIcon?: {src: string; srcSet?: string};amenityTitle?: string;amenityDesc?: string;iconVisible?: boolean; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, gwoKPoO7e, TAyogJfoL, W71hU8lit, xFjzm0Bu1, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "SsKPhxJje", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className, sharedStyle1.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1l27sjz", className, classNames)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"SsKPhxJje"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-88600824-c611-42c0-bfd8-2192e55a0f69, rgb(240, 231, 214))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", ...style}}>{xFjzm0Bu1 && (<Image background={{alt: "", fit: "fill", sizes: "32px", ...toResponsiveImage(gwoKPoO7e)}} className={"framer-151clp1"} data-framer-name={"Icon"} layoutDependency={layoutDependency} layoutId={"yWQcwso1w"}/>)}<RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1vhr6hc"} data-styles-preset={"tittoIAe8"} style={{"--framer-text-alignment": "center"}}>Luxurious Bedding</motion.p></React.Fragment>} className={"framer-yoazh5"} data-framer-name={"Title"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"fsWD6PbrI"} style={{"--framer-paragraph-spacing": "0px"}} text={TAyogJfoL} verticalAlignment={"top"} withExternalLayout/><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1er78qb"} data-styles-preset={"d0WA5d5b_"} style={{"--framer-text-alignment": "center"}}>Each bedroom features a plush king-sized bed, premium linens, and tasteful decor for a serene retreat.</motion.p></React.Fragment>} className={"framer-1y6halt"} data-framer-name={"Desc"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"ONgzBIE0D"} style={{"--framer-paragraph-spacing": "0px"}} text={W71hU8lit} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-XZqg2.framer-14phy2n, .framer-XZqg2 .framer-14phy2n { display: block; }", ".framer-XZqg2.framer-1l27sjz { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 20px; height: min-content; justify-content: flex-start; overflow: visible; padding: 48px 32px 48px 32px; position: relative; width: 426px; }", ".framer-XZqg2 .framer-151clp1 { flex: none; height: 32px; overflow: hidden; position: relative; width: 32px; }", ".framer-XZqg2 .framer-yoazh5, .framer-XZqg2 .framer-1y6halt { flex: none; height: auto; position: relative; white-space: pre-wrap; width: 100%; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-XZqg2.framer-1l27sjz { gap: 0px; } .framer-XZqg2.framer-1l27sjz > * { margin: 0px; margin-bottom: calc(20px / 2); margin-top: calc(20px / 2); } .framer-XZqg2.framer-1l27sjz > :first-child { margin-top: 0px; } .framer-XZqg2.framer-1l27sjz > :last-child { margin-bottom: 0px; } }", ...sharedStyle.css, ...sharedStyle1.css, ".framer-XZqg2[data-border=\"true\"]::after, .framer-XZqg2 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 224
 * @framerIntrinsicWidth 426
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"gwoKPoO7e":"amenityIcon","TAyogJfoL":"amenityTitle","W71hU8lit":"amenityDesc","xFjzm0Bu1":"iconVisible"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameraQs2Vpfk8: React.ComponentType<Props> = withCSS(Component, css, "framer-XZqg2") as typeof Component;
export default FrameraQs2Vpfk8;

FrameraQs2Vpfk8.displayName = "Amenity";

FrameraQs2Vpfk8.defaultProps = {height: 224, width: 426};

addPropertyControls(FrameraQs2Vpfk8, {gwoKPoO7e: {title: "Amenity Icon", type: ControlType.ResponsiveImage}, TAyogJfoL: {defaultValue: "Amenity Title", description: "", placeholder: "", title: "Amenity Title", type: ControlType.String}, W71hU8lit: {defaultValue: "Amenity Desc", description: "", displayTextArea: false, placeholder: "", title: "Amenity Desc", type: ControlType.String}, xFjzm0Bu1: {defaultValue: true, title: "Icon Visible", type: ControlType.Boolean}} as any)

addFonts(FrameraQs2Vpfk8, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts), ...getFontsFromSharedStyle(sharedStyle1.fonts)], {supportsExplicitInterCodegen: true})